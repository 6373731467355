.nav-bar {
  background: #faf6ed;
  width: 100%;
  overflow: auto;
  height: 60px;
  box-shadow: 0px 5px 20px #bebab3;
  position: fixed;
  top: 0;
  z-index: 100;

  nav {
    padding-left: 8px;

    a {
      float: left;
      text-align: center;
      padding-top: 12px;
      padding-left: 15px;
      padding-right: 17px;

      font-size: 22px;
      color: #484847;
      line-height: 34px;
      height: 34px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #5cb25d;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &::after {
          content: 'HOME';
        }
      }
    }

    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.active {
      svg {
        color: #5cb25d;
      }
    }
  }

  ul {
    float: right;
    display: flex;
    flex-direction: row;
    list-style: none;
    text-align: center;
    padding-top: 12px;
    padding-left: 15px;
    padding-right: 17px;
    margin: 9px 0;

    li {
      a {
        padding: 0 9px;
        font-size: 18px;
        line-height: 10px;

        &:hover svg {
          color: #5cb25d;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .nav-bar a {
    float: none;
    display: block;
  }
}
